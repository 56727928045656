<template>
  <div>
    <!-- Page Header Start -->
    <div class="page-title-header">
      <div class="page-header">
        <b-breadcrumb class="m-0">
          <b-breadcrumb-item
            :to="{
              name: 'DashboardHome',
            }"
          >
            <i class="fa fa-home"></i>
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            預約管理
          </b-breadcrumb-item>

          <b-breadcrumb-item :to="{ name: 'BookingPresetList' }">
            預約設定檔列表
          </b-breadcrumb-item>

          <b-breadcrumb-item active
            >{{
              isEditing ? '編輯預約設定檔' : '新增預約設定檔'
            }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <!-- Page Header End -->

    <!-- Form Start -->
    <div class="email-wrapper wrapper">
      <div class="row bg-white">
        <div class="col-md-2 mail-sidebar pt-3">
          <div class="menu-bar">
            <ul class="menu-items">
              <li :class="{ active: step == '', 'has-checkbox': true, 'has-checkbox--checked': bookingPreset.id }" @click="goToStep('')">
                <a>基本設定</a>
              </li>
              <li :class="{ active: step == '#fields', 'has-checkbox': true, 'has-checkbox--checked': deepGet(bookingPreset, 'config.fields.length', 0) > 0, disabled: !bookingPreset.id }" @click="goToStep('#fields')">
                <a>資料集欄位</a>
              </li>
              <li :class="{ active: step == '#mapping', disabled: !bookingPreset.id }" @click="goToStep('#mapping')">
                <a>預約資料設定</a>
              </li>
              <li :class="{ active: step == '#booking-setting', disabled: !bookingPreset.id }" @click="goToStep('#booking-setting')">
                <a>預約設定</a>
              </li>
              <li :class="{ active: step == '#pages-and-sections', disabled: !bookingPreset.id }" @click="goToStep('#pages-and-sections')">
                <a>分頁與分區設定</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-10 main-view">
          <b-overlay :show="showLoading">
            <b-card>
              <!-- 基本資料 section start -->
              <section v-if="step == ''">
                <!-- 欄位部分 Start -->
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">{{ isEditing ? '編輯預約設定檔' : '新增預約設定檔' }}</h4>
                  <div class="col-12 col-xl-8">
                    <validation-error-alert
                      v-if="validationErrors"
                      :errors="validationErrors"
                    ></validation-error-alert>
                    <!-- 名稱 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="*預約設定檔名稱"
                    >
                      <b-form-input
                        class="mb-2 mr-sm-2 mb-sm-0"
                        type="text"
                        :state="v$.bookingPreset.name.$error ? false: null"
                        v-model="bookingPreset.name"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="!v$.bookingPreset.name.$error">
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 名稱 End -->
                    <!-- 類型 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="*預約設定檔類型"
                    >
                      <b-form-radio-group
                        v-model="bookingPreset.type"
                        :options="bookingPresetTypes"
                      ></b-form-radio-group>
                    </b-form-group>
                    <!-- 類型 End -->
                    <!-- 描述 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="預約設定檔說明"
                    >
                      <b-form-textarea
                        class="mb-2 mr-sm-2 mb-sm-0"
                        v-model="bookingPreset.config.description"
                      ></b-form-textarea>
                    </b-form-group>
                    <!-- 描述 End -->
                    <!-- 瀏覽器紀錄 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="是否開放預約"
                      description="是否允許使用者新增預約"
                    >
                      <b-form-checkbox v-model="bookingPreset.config.is_enabled" :value="true" :unchecked-value="false">啟用</b-form-checkbox>
                    </b-form-group>
                    <!-- 瀏覽器紀錄 End -->
                  </div>
                </div>
                <!-- 欄位部分 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 基本資料 section end -->

              <!-- 欄位設定 section start -->
              <section v-if="step == '#fields'">
                <div>
                  <DynamicFormEditor
                    v-if="bookingPreset.id"
                    @output="saveFields"
                    @cancel="$router.push({ name: 'BookingPresetList' })"
                    editor-title="預約欄位編輯"
                    :form-type="'booking'"
                    :input-subjects="bookingPreset.config.fields"
                    :pages="bookingPreset.config.pages"
                    :sections="bookingPreset.config.sections"
                  ></DynamicFormEditor>
                </div>
              </section>
              <!-- 欄位設定 section end -->

              <!-- 預約資料設定 section start -->
              <section v-if="step == '#mapping'">
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">預約資料設定</h4>
                  <div class="col-12 col-xl-8">
                    <!-- 標題 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="標題"
                    >
                      <b-form-select
                        v-model="bookingPreset.config.booking_mapping.bookings.title"
                        :options="collectionFieldCandidates"
                      ></b-form-select>
                    </b-form-group>
                    <!-- 標題 End -->
                    <!-- 預約開始欄位 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="預約開始欄位"
                    >
                      <b-form-select
                        v-model="bookingPreset.config.booking_mapping.bookings.start_at"
                        :options="collectionFieldCandidates"
                      ></b-form-select>
                    </b-form-group>
                    <!-- 預約開始欄位 End -->
                    <!-- 預約結束欄位 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="預約結束欄位"
                    >
                      <b-form-select
                        v-model="bookingPreset.config.booking_mapping.bookings.end_at"
                        :options="collectionFieldCandidates"
                      ></b-form-select>
                    </b-form-group>
                    <!-- 預約結束欄位 End -->
                    <!-- 預約狀態欄位 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="預約狀態欄位"
                    >
                      <b-form-select
                        v-model="bookingPreset.config.booking_mapping.bookings.status"
                        :options="collectionFieldCandidates"
                      ></b-form-select>
                    </b-form-group>
                    <!-- 預約狀態欄位 End -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="是否啟用選擇分店"
                    >
                      <b-form-checkbox v-model="bookingPreset.config.enabled_selected_branch"></b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="是否啟用選擇員工"
                    >
                      <b-form-checkbox v-model="bookingPreset.config.enabled_selected_staff"></b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="服務項目欄位"
                    >
                      <b-form-select
                        v-model="bookingPreset.config.service.collection_id"
                        :options="collectionOptions"
                      ></b-form-select>
                    </b-form-group>
                    <!-- 精靈模式 service特殊設定欄位 -->
                    <div v-if="bookingPreset.config.service.collection_id">
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-size="sm"
                        label="圖片（精靈模式 圖片網址）"
                      >
                        <b-form-select
                          v-model="bookingPreset.config.service.image_item_id"
                          :options="collectionItemOptions"
                        >
                        </b-form-select>
                      </b-form-group>
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-size="sm"
                        label="標題（精靈模式 服務標題）"
                      >
                        <b-form-select
                          v-model="bookingPreset.config.service.title_item_id"
                          :options="collectionItemOptions"
                        >
                        </b-form-select>
                      </b-form-group>
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-size="sm"
                        label="敘述（精靈模式 服務敘述）"
                      >
                        <b-form-select
                          v-model="bookingPreset.config.service.description_item_id"
                          :options="collectionItemOptions"
                        >
                        </b-form-select>
                      </b-form-group>
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-size="sm"
                        label="價格（精靈模式 價格）"
                      >
                        <b-form-select
                          v-model="bookingPreset.config.service.price_item_id"
                          :options="collectionItemOptions"
                        >
                        </b-form-select>
                      </b-form-group>
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-size="sm"
                        label="時間（精靈模式 時間長短分鐘計算）"
                      >
                        <b-form-select
                          v-model="bookingPreset.config.service.duration_item_id"
                          :options="collectionItemOptions"
                        >
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <!-- 精靈模式 service特殊設定欄位 -->
                    <!-- 預約狀態對應 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="預約狀態對應"
                    >
                      <JsonEditor
                        height="128px"
                        v-model="bookingPreset.config.provider_config.status_filter_mapping"
                        ref="statusFilterMapping"
                      />
                      <b-form-invalid-feedback :state="!v$.bookingPreset.config.provider_config.status_filter_mapping.$error">
                        {{ presentValidationErrors('bookingPreset.config.provider_config.status_filter_mapping') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 預約狀態對應 End -->
                    <!-- 前台預約清單頁籤設定 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="前台預約清單頁籤設定"
                    >
                      <JsonEditor
                        height="128px"
                        v-model="bookingPreset.config.provider_config.booking_status_mapping"
                        ref="bookingStatusMapping"
                      />
                      <b-form-invalid-feedback :state="!v$.bookingPreset.config.provider_config.booking_status_mapping.$error">
                        {{ presentValidationErrors('bookingPreset.config.provider_config.booking_status_mapping') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 前台預約清單頁籤設定 End -->
                    <!-- 前台預約清單欄位 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="前台預約清單欄位"
                    >
                      <JsonEditor
                        height="128px"
                        v-model="bookingPreset.config.liff_field_setting.list"
                        ref="liffFieldSetting"
                      />
                      <b-form-invalid-feedback :state="!v$.bookingPreset.config.liff_field_setting.list.$error">
                        {{ presentValidationErrors('bookingPreset.config.liff_field_setting.list') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 前台預約清單欄位 End -->
                  </div>
                </div>
                <!-- 欄位部分 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 預約資料設定 section end -->

              <!-- 預約設定 section start -->
              <section v-if="step == '#booking-setting'">
                <!-- 欄位部分 Start -->
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">預約設定</h4>
                  <div class="col-12 col-xl-8">
                    <validation-error-alert
                      v-if="validationErrors"
                      :errors="validationErrors"
                    ></validation-error-alert>
                    <!-- 填寫後通知 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="填寫後貼標"
                    >
                      <TagsBox v-model="bookingPreset.config.event_setting.booking_tags"></TagsBox>
                    </b-form-group>
                    <!-- 填寫後通知 End -->
                  </div>
                </div>
                <!-- 欄位部分 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 預約設定 section end -->

              <!-- 分頁與分區設定 section start -->
              <section v-if="step == '#pages-and-sections'">
                <h4 class="mb-3 font-weight-bold">分頁與分區設定</h4>
                <!-- 分頁管理 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="分頁管理"
                >
                  <JsonEditor
                    height="256px"
                    v-model="bookingPreset.config.pages"
                    ref="pageManagement"
                  />
                  <b-form-invalid-feedback :state="!v$.bookingPreset.config.pages.$error">
                    {{ presentValidationErrors('bookingPreset.config.pages') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- 分頁管理 End -->
                <!-- 分區管理 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="分區管理"
                >
                  <JsonEditor
                    height="256px"
                    v-model="bookingPreset.config.sections"
                    ref="sectionManagement"
                  />
                  <b-form-invalid-feedback :state="!v$.bookingPreset.config.sections.$error">
                    {{ presentValidationErrors('bookingPreset.config.sections') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- 分區管理 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center mt-2">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 分頁與分區設定 section end -->
            </b-card>
          </b-overlay>
        </div>
      </div>
    </div>
    <!-- Form End -->

  </div>
</template>

<script>
import { zh } from "vuejs-datepicker/dist/locale";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import bookingApi from "@/apis/booking";
import JsonEditor from '@/components/Dashboard/JsonEditor.vue';
import DynamicFormEditor from '@/components/DynamicFormEditor/DynamicFormEditor.vue';
import TagsBox from "@/components/TagsBox";
import mergeDefaultAndDataModel from "@/utils/mergeDefaultAndDataModel";
import deepGet from "lodash/get";
import { invoke } from "lodash";
import { v4 as uuidv4 } from 'uuid'
import { mapGetters } from "vuex";
import collectionApi from "@/apis/collection";

export default {
  components: {
    JsonEditor,
    DynamicFormEditor,
    TagsBox,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      bookingPreset: {
        name: { required },
        type: { required },
        config: {
          provider_config: {
            status_filter_mapping: !(this.isEditing && this.step === '#mapping') ? {} : {
              "array": helpers.withMessage(
                "必須為 Array 格式",
                (value, vm) => invoke(vm, '$refs.statusFilterMapping.isValidArray') || false,
              ),
            },
            booking_status_mapping: !(this.isEditing && this.step === '#mapping') ? {} : {
              "array": helpers.withMessage(
                "必須為 Array 格式",
                (value, vm) => invoke(vm, '$refs.bookingStatusMapping.isValidArray') || false,
              ),
            },
          },
          liff_field_setting: {
            list: !(this.isEditing && this.step === '#mapping') ? {} : {
              "array": helpers.withMessage(
                "必須為 Array 格式",
                (value, vm) => invoke(vm, '$refs.liffFieldSetting.isValidArray') || false,
              ),
            },
          },
          pages: !(this.isEditing && this.step === '#pages-and-sections') ? {} : {
            "array": helpers.withMessage(
              "必須為 Array 格式",
              (value, vm) => invoke(vm, '$refs.pageManagement.isValidArray') || false,
            ),
          },
          sections: !(this.isEditing && this.step === '#pages-and-sections') ? {} : {
            "array": helpers.withMessage(
              "必須為 Array 格式",
              (value, vm) => invoke(vm, '$refs.sectionManagement.isValidArray') || false,
            ),
          },
        },
      }
    };
  },
  computed: {
    ...mapGetters("route", ["routeQuery"]),
    collectionFieldCandidates() {
      return [
        { text: '未設定', value: null },
        ...this.bookingPreset.config.fields.map(field => ({ text: deepGet(field, 'config.title'), value: deepGet(field, 'config._id') })),
      ]
    },
    collectionItemOptions() {
      return this.collectionOptions.find((collection) => collection.value == this.bookingPreset.config.service.collection_id)?.config?.fields.map((col) => {
        return {
          value: col.config._id,
          text: col.config.title
        }
      })
    }
  },
  data() {
    return {
      zh,
      isEditing: false,
      showLoading: false,
      validationErrors: null,
      step: '',
      bookingPreset: {
        name: null,
        type: 'normal',
        status: 'draft',
        config: {
          is_enabled: false,
          fields: [],
          description: null,
          booking_mapping: {
            bookings: {
              title: null,
              status: null,
              start_at: null,
              end_at: null,
            }
          },
          provider_config: {
            status_filter_mapping: null,
            booking_status_mapping: null,
          },
          liff_field_setting: {
            list: null,
          },
          event_setting: {
            booking_tags: [],
          },
          enabled_selected_staff: false,
          enabled_selected_branch: false,
          service: {
            collection_id: null,
            image_item_id: null,
            title_item_id: null,
            description_item_id: null,
            price_item_id: null,
            duration_item_id: null,
          },
          pages: [],
          sections: [],
        },
      },
      bookingPresetTypes: [
        { text: '一般模式', value: 'normal' },
        { text: '精靈模式', value: 'wizard' },
      ],
      collectionOptions:[],
    }
  },
  mounted() {
    if (this.$route.name === "BookingPresetEdit") {
      this.isEditing = true;
      this.bookingPreset.id = this.$route.params.id;
      this.fetchBookingPreset();
      this.navigateToStepFromCurrentRoute();
    } else {
      this.isEditing = false;
      this.$set(this, 'bookingPreset', this.mergeDefaultAndDataModel(this.bookingPreset, null));
    }
    this.fetchCollections();
  },
  methods: {
    deepGet,
    mergeDefaultAndDataModel,
    async fetchCollections() {
      try {
        let response = await collectionApi.getCollections({
          with_items: 1,
          is_enabled: 1,
        });
        this.collectionOptions = response.data.data.map((collection) => {
          return {
            value: collection.id,
            text: collection.name + ' 共 ' + collection.items_count + ' 筆資料',
            items: collection.items,
            config: collection.config,
          };
        });
      } catch (error) {
        console.error(error);
      }
    },
    presentValidationErrors(validationKey) {
      return deepGet(this.v$, validationKey + '.$errors', []).map(error => error.$message).join(', ')
    },
    cancel() {
      this.$router.push({ name: 'BookingPresetList', query: this.routeQuery });
    },
    goToStep(step) {
      if (!this.bookingPreset.id) {
        return;
      }

      if (this.step == step) {
        return;
      }

      this.step = step
      if (this.$route.hash !== this.step) {
        this.$router.replace({ hash: this.step });
      }
    },
    navigateToStepFromCurrentRoute() {
      const anchor = this.$route.hash;
      if (anchor) {
        this.goToStep(anchor);
      }
    },
    async submit() {
      const result = await this.v$.$validate();
      if (!result) return false;
      try {
        this.showLoading = true;
        // 編輯表單
        if (this.isEditing) {
          await bookingApi.updateBookingPreset(this.bookingPreset.id, this.bookingPreset);
          this.$swal("儲存成功", "", "success");
        }
        // 新增表單
        else {
          const { pages, sections } = this.createDefaultPageAndSections();
          this.$set(this.bookingPreset.config, 'pages', pages);
          this.$set(this.bookingPreset.config, 'sections', sections);
          let response = await bookingApi.createBookingPreset({ ...this.bookingPreset });
          this.bookingPreset = response.data.data;
          this.$swal("新增成功", "", "success");
          this.isEditing = true;
          this.$router.replace({ name: "BookingPresetEdit", params: { id: response.data.data.id } })
        }

        return true;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存失敗", "error");
        return false;
      } finally {
        this.showLoading = false;
      }
    },
    async fetchBookingPreset() {
      if (! this.bookingPreset.id) {
        this.$swal("錯誤", "無法取得讀取 ID", "error");
        return;
      }

      try {
        this.showLoading = true;
        let response = await bookingApi.getBookingPreset(this.bookingPreset.id);
        this.$set(this, 'bookingPreset', this.mergeDefaultAndDataModel(this.bookingPreset, response.data.data));
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料錯誤", "error");
        this.cancel();
      } finally {
        this.showLoading = false;
      }
    },
    async saveFields(fields) {
      try {
        this.showLoading = true;

        this.bookingPreset.config.fields = fields;

        await bookingApi.updateBookingPreset(this.bookingPreset.id, this.bookingPreset);
        this.$swal("儲存成功", "", "success");
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存資料錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    createDefaultPageAndSections() {
      const pages = (Array.from(Array(3))).map((empty, index) => {
        return {
          _id: uuidv4(),
          order: index + 1,
          title: `第${index + 1}頁`,
        }
      })

      const sections = pages.reduce((carry, page) => {
        return carry.concat((Array.from(Array(2))).map((empty, index) => {
          return {
            _id: uuidv4(),
            order: index + 1,
            title: `第${page.order}-${index + 1}區`,
            page_id: page._id,
            visible_when_any: [],
          }
        }))
      }, [])

      return { pages, sections }
    },
  }
}
</script>

<style lang="scss" scoped>
.menu-items > li {
  cursor: pointer;

  &.disabled {
    background-color: #ddd;
    opacity: 0.5;
    pointer-events: none;
  }

  &.has-checkbox {
    &:after {
      content: "\f134";
      font-family: "Material Design Icons";
      font-size: 16px;
    }
  }

  &.has-checkbox.has-checkbox--checked {
    &:after {
      content: "\f133";
      color: green;
    }
  }
}
</style>
